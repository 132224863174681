import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);
  allRoute: any;

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }
  // <mat-option [value]="1">Administrador</mat-option>
  // <mat-option [value]="2">Producción</mat-option>
  // <mat-option [value]="3">Caja</mat-option>
  // <mat-option [value]="4">Jugos</mat-option>
  // <mat-option [value]="5">Pastelero</mat-option>
  // <mat-option [value]="6">Helados</mat-option>
  constructor(private readonly layoutService: VexLayoutService) {
    let rol = localStorage.getItem("rol");

    switch (rol) {
      case "1":
        this.allRoute = [
          {
            type: 'link',
            label: 'Empleados',
            route: '/apps/empleados',
            icon: 'mat:group'
          },
          {
            type: 'link',
            label: 'Categorías',
            route: '/apps/categorias',
            icon: 'mat:category'
          },
          {
            type: 'link',
            label: 'Productos',
            route: '/apps/productos',
            icon: 'mat:inventory'
          },
          {
            type: 'link',
            label: 'Clientes',
            route: '/apps/clientes',
            icon: 'mat:groups'
          },
          {
            type: 'link',
            label: 'Ventas',
            route: '/apps/ventas',
            icon: 'mat:point_of_sale'
          },
          {
            type: 'link',
            label: 'Jugos',
            route: '/apps/jugos',
            icon: 'mat:call_received'
          },
          {
            type: 'link',
            label: 'Helados',
            route: '/apps/helados',
            icon: 'mat:call_received'
          },
          {
            type: 'link',
            label: 'Pedidos Tortas',
            route: '/apps/pedidos',
            icon: 'mat:inventory_2'
          },
          {
            type: 'link',
            label: 'R. diarios',
            route: '/apps/diarios',
            icon: 'mat:savings'
          },
          {
            type: 'link',
            label: 'Reportes',
            route: '/apps/reportes',
            icon: 'mat:description'
          }
        ];
        break;
      case "2":
        this.allRoute = [

          {
            type: 'link',
            label: 'Productos',
            route: '/apps/productos',
            icon: 'mat:inventory'
          },

          {
            type: 'link',
            label: 'Pedidos Tortas',
            route: '/apps/pedidos',
            icon: 'mat:inventory_2'
          },
        ];
        break;
      case "3":
        this.allRoute = [

          {
            type: 'link',
            label: 'Clientes',
            route: '/apps/clientes',
            icon: 'mat:groups'
          },
          {
            type: 'link',
            label: 'Ventas',
            route: '/apps/ventas',
            icon: 'mat:point_of_sale'
          },
          {
            type: 'link',
            label: 'Jugos',
            route: '/apps/jugos',
            icon: 'mat:call_received'
          },
          {
            type: 'link',
            label: 'Helados',
            route: '/apps/helados',
            icon: 'mat:call_received'
          },
          {
            type: 'link',
            label: 'Pedidos Tortas',
            route: '/apps/pedidos',
            icon: 'mat:inventory_2'
          }

        ];
        break;
      case "4":
        this.allRoute = [

          {
            type: 'link',
            label: 'Jugos',
            route: '/apps/jugos',
            icon: 'mat:call_received'
          },

        ];
        break;
      case "5":
        this.allRoute = [

          {
            type: 'link',
            label: 'Clientes',
            route: '/apps/clientes',
            icon: 'mat:groups'
          },
          {
            type: 'link',
            label: 'Pedidos Tortas',
            route: '/apps/pedidos',
            icon: 'mat:inventory_2'
          }

        ];
        break;
      case "6":
        this.allRoute = [
          {
            type: 'link',
            label: 'Helados',
            route: '/apps/helados',
            icon: 'mat:call_received'
          },
          {
            type: 'link',
            label: 'Productos',
            route: '/apps/productos',
            icon: 'mat:inventory'
          },
        ];
        break;
        // case "7":
        //   this.allRoute = [
        //     {
        //       type: 'link',
        //       label: 'Productos',
        //       route: '/apps/productos',
        //       icon: 'mat:inventory'
        //     },
        //     {
        //       type: 'link',
        //       label: 'Helados',
        //       route: '/apps/helados',
        //       icon: 'mat:call_received'
        //     }
        //   ];
        //   break;

    }
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Apps',
        children: this.allRoute
      }
    ]);
  }
}
