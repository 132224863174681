import { Component } from '@angular/core';

@Component({
  selector: 'vex-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss']
})
export class ConfirmarComponent {

}
