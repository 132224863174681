import { Component, OnInit, Inject } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { SerService } from 'src/app/services/ser.service';
import { ActivatedRoute, ParamMap } from '@angular/router'
import { VentaService } from 'src/app/services/venta.service';

import moment from 'moment';
import 'moment/locale/es';
import { CommonModule, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { AngularMaterialModule } from 'src/app/angular-material.module';

@Component({
  standalone: true,
  selector: 'vex-detalle-venta-pedido',
  templateUrl: './detalle-venta-pedido.component.html',
  styleUrls: ['./detalle-venta-pedido.component.scss'],
  imports: [
    VexPageLayoutComponent,
    VexPageLayoutHeaderDirective,
    VexBreadcrumbsComponent,
    VexPageLayoutContentDirective,
    NgIf,
    NgFor,
    NgClass,
    AngularMaterialModule,
    CommonModule
  ]
})
export class DetalleVentaPedidoComponent {
  id: any;





  date: any;
  receipt :any;
  data: any;
  bodyMessageCliente: any;
  constructor(
    private route: ActivatedRoute,
    private service: VentaService) { }

  ngOnInit(): void {
    this.bodyMessageCliente ="Hola";
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      console.log(this.id);
      this.service.getPedidosD(this.id).subscribe((data: any) => {
        this.receipt = data.data;
        console.log("servicio funcionando", this.receipt);
        moment.locale('es');
        console.log(this.receipt);
        this.date =   moment(this.receipt['fecha']).format('LL');
        console.log('>>>>>>>', this.date); //DICIEMBRE POR EJ

      }, err => {
        console.log(err);

      });

    })



  }


  sendWhatshappClientt() {
    
    //  this.ext = '';
    //     this.defaults.detalle.forEach(element => {
    //       console.log(element);
    //       this.ext +=  ` ${element.plato + ' ' + element.cantidad},\n\n   `
    //       // this.dataSource.push(element);
    //   });
    // console.log(this.ext);
    // let ext
    // const bodyMessage = `Hello ${}\n\nI'm here to tell you your child's routine on ${date}.\n\nHis/her entry was at ${entry} and his/her exit at ${exit}.\n\nI will tell you how the student's meals were on the day! The student's meals occurred as follows:\ncollation: ${collation}\nlunch: ${lunch}\nsnack: ${snack}\ndinner: ${dinner}\n\n${descriptionRemedy}`;
    // const bodyMessage = `Hola ${this.defaults.cliente_nombres}\n\n ${this.defaults.cliente_apellidos}\n deseas confirmar tu pedido para realizar tu entrega a domicilio.?`;
    // return bodyMessage;
  }
  sendWhatshappRes() {
    //  this.ext = '';
    //     this.defaults.detalle.forEach(element => {
    //       console.log(element);
    //       this.ext +=  ` ${element.plato + ' ' + element.cantidad},\n\n   `
    //       // this.dataSource.push(element);
    //   });
    // console.log(this.ext);
    // // let ext
    // // const bodyMessage = `Hello ${}\n\nI'm here to tell you your child's routine on ${date}.\n\nHis/her entry was at ${entry} and his/her exit at ${exit}.\n\nI will tell you how the student's meals were on the day! The student's meals occurred as follows:\ncollation: ${collation}\nlunch: ${lunch}\nsnack: ${snack}\ndinner: ${dinner}\n\n${descriptionRemedy}`;
    // const bodyMessage = `Hola ${this.defaults.restaurante_nombre}\n tienes un pedido pendiente por favor revisa tu App para aceptar o rechazar el pedido?`;
    // return bodyMessage;
  }
}
