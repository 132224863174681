<!-- receipt.component.html -->

    <vex-page-layout-content *ngIf="receipt">
        <div class="logo-container">
            <img style=" width: 150px;" src="assets/img/amor.png">
        </div>
        <div align="center">
            <a target="_blank" href="{{receipt.link}}">
            <button mat-raised-button color="accent">Ir a Ubicación</button>
            </a><br><br>
            <a  target="_blank"  href="https://api.whatsapp.com/send?phone=591{{receipt.celular}}&text={{bodyMessageCliente}}">
            
              <button mat-raised-button color="accent" >Contactar cliente</button>&nbsp;
            </a>
        </div>
        <div id="print-section-1" style="overflow-y: scroll; height:400px;">
            <!-- receipt.component.html -->

            <div class="page-container">
                Page
                <span class="page"></span>
                of
                <span class="pages"></span>
            </div>

          

            <table class="invoice-info-container">
                <tr>
                    <td rowspan="2" class="client-name">
                        {{ receipt.cliente_nombre === 'Sin cliente' ? receipt.nombre_completo : receipt.cliente_nombre}}
                    </td>
                    <td>
                        Cochabamba - Bolivia
                    </td>
                </tr>
                <tr>
                    <td>
                        Calle Benjamín blanco N*514 Entre Calama y Ricardo Terrazas
                    </td>
                </tr>
                <tr>
                    <td>
                        Fecha de recibo: <strong>{{ date }}</strong>
                    </td>
                    <td>
                        San Francisco CA, 94103
                    </td>
                </tr>
                <tr>
                    <td>
                        Recibo #: <strong>{{ receipt.id }}</strong>
                    </td>
                    <td>
                        contacto@elamoresdulcebolivia.com
                    </td>
                </tr>
            </table>

            <table class="line-items-container">
                <thead>
                    <tr align="center">
                        <th class="heading-quantity">Cantidad</th>
                        <th class="heading-description">Producto</th>
                        <th class="heading-price">Precio Unitario</th>
                        <th class="heading-subtotal">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detalle of receipt.detalleventa">
                        <ng-container *ngIf="detalle.tipo_producto == '0'">
                            <td>{{ detalle.cantidad }}</td>
                            <td>{{ detalle.producto.nombre }}</td>
                            <td>{{ detalle.precio }}</td>
                            <td>{{ detalle.cantidad * detalle.precio}}</td>
                        </ng-container>

                        <ng-container *ngIf="detalle.tipo_producto == '1'">
                            <td>{{ detalle.cantidad }}</td>
                            <td>{{ detalle.producto.nombre }} {{'Detalle: ' + detalle.detalle}}
                                {{'Temática: ' + detalle.tematica}} {{'Sabor: ' + detalle.sabor_torta}} {{'Tamaño: ' +
                                detalle.tamanio}}
                                <!-- <ng-container *ngFor="let foto of detalle.fotos_url">
                <img [src]="foto.foto" alt="detalle" style="width: 150px;">
              </ng-container> -->
                            </td>

                            <td>{{ detalle.precio }}</td>
                            <td>{{ detalle.cantidad * detalle.precio}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>


            <table class="line-items-container has-bottom-border">
                <thead>
                    <tr>
                        <th>Número de Cuenta</th>
                        <th>Due By</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="payment-info">
                            <div>
                                Cuenta No: <strong>123567744</strong>
                            </div>
                            <div>
                                <!-- Routing No: <strong>120000547</strong> -->
                                <!-- Método de Pago:<strong> {{ receipt.tipo_pago }}</strong> -->
                            </div>
                        </td>
                        <td class="large">{{date}}</td>
                        <td class="large total">Bs.{{ receipt.total }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="footer">
                <div class="footer-info">
                    <span> contacto@elamoresdulcebolivia.com
                    </span> |
                    <span>+591 62409973</span> |
                    <span>www.elamoresdulcebolivia.com</span>
                </div>
                <div class="footer-thanks">
                    <img src="https://github.com/anvilco/html-pdf-invoice-template/raw/main/img/heart.png" alt="heart">
                    <span>Muchas gracias!</span>
                </div>
            </div>
        </div>
    </vex-page-layout-content>
